import React, { Component } from "react";
import ToggleSideNav from "../UI/ToggleSideNav/ToggleSideNav";
import settingsMethods from "../../services/settingsApi";
import CheckBox from "../UI/CheckBox/CheckBox";
import Modal from "@material-ui/core/Modal";

import Tooltip from "@material-ui/core/Tooltip";
import WifiIcon from "@material-ui/icons/Wifi";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import DeleteIcon from "@material-ui/icons/Delete";
import MomentUtils from "@date-io/moment";

// import DateFnsUtils from "@date-io/date-fns";

import {
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import Moment from "@date-io/moment";
import moment from "moment/moment";
import { toast } from "react-toastify";
import mqtt from "mqtt";
import CustomToolTip from "../Common/CustomToolTip";
import { AutoScan } from "./messages";
import { EditOutlined } from "@material-ui/icons";
const scan_option = [
  { value: "every_weekday", label: "Every Weekday" },
  { value: "daily", label: "Daily" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "yearly", label: "Yearly" },
];
const month_list = [
  { value: "january", label: "January" },
  { value: "february", label: "February" },
  { value: "march", label: "March" },
  { value: "april", label: "April" },
  { value: "may", label: "May" },
  { value: "june", label: "June" },
  { value: "july", label: "July" },
  { value: "august", label: "August" },
  { value: "september", label: "September" },
  { value: "october", label: "October" },
  { value: "november", label: "November" },
  { value: "december", label: "December" },
];

const list = [
  { value: "1", label: "First" },
  { value: "2", label: "Second" },
  { value: "3", label: "Third" },
  { value: "4", label: "Fourth" },
  { value: "-1", label: "Last" },
];

class VulnerabilityScan extends Component {
  state = {
    connect_pref: "",
    unique_id: false,
    scan_name: "",
    quarantine_status: "none",
    openListModal: false,
    selected_day: "Monday",
    scan_option: "daily",
    scanType: "quick_scan",
    scan_config: "full_and_fast",
    repeat_every: "1",
    days_of_month: "1",
    week_occurence: "1",
    month: "january",
    run_from: Date.now(),
    run_until: Date.now(),
    getSettings: [],
    device_id: JSON.parse(window.sessionStorage.getItem("ConnectedDevice"))
      ?.device_id,
    role_id: JSON.parse(window.sessionStorage.getItem("ConnectedDevice"))
      ?.role_id,
    showLogout: false,
    settings: [
      {
        id: 1,
        value: "openvas_autoscan_flag",
        name: "Auto Scan",
        isChecked: false,
        isDisabled: false,
      },
    ],
    devices: [
      {
        id: 6,
        value: "notification",
        name: "Notification",
        isChecked: false,
        isDisabled: false,
      },
      {
        id: 7,
        value: "remote_mgt",
        name: "Remote Management",
        isChecked: false,
        isDisabled: false,
      },
      {
        id: 8,
        value: "software_update",
        name: "Software Update",
        isChecked: false,
        isDisabled: false,
      },
    ],
    securities: [
      {
        id: 9,
        value: "protect_mode",
        name: "Protect Mode",
        isChecked: false,
        isDisabled: false,
      },
      {
        id: 10,
        value: "monitoring_mode",
        name: "Monitoring Mode",
        isChecked: false,
        isDisabled: false,
      },
      {
        id: 11,
        value: "ghost_mode",
        name: "Ghost Mode",
        isChecked: false,
        isDisabled: false,
      },
      // {
      //   id: 12,
      //   value: "encryption_mode",
      //   name: "Encryption Mode",
      //   isChecked: false,
      // },
      // { id: 13, value: "auto_vpn", name: "Auto VPN", isChecked: false },
    ],
    daysList: [
      { id: 1, name: "Monday", isChecked: false },
      { id: 2, name: "Tuesday", isChecked: false },
      { id: 3, name: "Wednesday", isChecked: false },
      { id: 4, name: "Thursday", isChecked: false },
      { id: 5, name: "Friday", isChecked: false },
      { id: 6, name: "Saturday", isChecked: false },
      { id: 7, name: "Sunday", isChecked: false },
    ],
    type: "",
    keyValue: 0,
    setting_id: 0,
    wifi_name_ssid: "",
    editModal: false,
    modalTitle: "SSID",
    modalLabel: "Enter new SSID",
    errorModal: "",
    updateType: "SSID",
    password: "",
    changePassword: "",
    modalPasswordLabel: "Enter confirm password",
    connectedDevices: [],
    open: false,
    message: "",
    time: "",
    selectDevicesList: [],
    reports: [],
    devicesList: [],
    records: {},
    // daysList: [
    //   { id: 1, name: "Monday", isChecked: false },
    //   { id: 2, name: "Tuesday", isChecked: false },
    //   { id: 3, name: "Wednesday", isChecked: false },
    //   { id: 4, name: "Thursday", isChecked: false },
    //   { id: 5, name: "Friday", isChecked: false },
    //   { id: 6, name: "Saturday", isChecked: false },
    //   { id: 7, name: "Sunday", isChecked: false },
    // ],
    dayRule: [],
  };

  async componentDidMount() {
    // this.fetchParentalControl();
    this.fetchScansReport();
    this.subscribe();
  }
  async componentWillUnmount() {
    this.unSubscribe();
  }

  subscribe = () => {
    console.log("mqtt");
    var mosquitto = mqtt.connect("wss://cylenium.io", {
      port: 8083,
      username: "mqttdonald",
      password: "mqttdonald",
      clean: true,
      retain: false,
    });
    mosquitto.on("connect", () => {
      var successTopic = this.state.device_id + "/device/app";
      //
      mosquitto.subscribe(successTopic);
    });

    mosquitto.on("message", (topic, sub_message) => {
      // if (sub_message??.toString !== "Buffer")
      if (typeof sub_message == "object") {
        let temp = JSON.parse(sub_message.toString());

        const tempRecords = this.state.reports?.length
          ? this.state.reports?.map((data) =>
              temp.result.unique_id == data.unique_id
                ? temp.module?.trim() == "scan_severity"
                  ? { ...data, severity: temp.result.value }
                  : { ...data, status: temp.result.value }
                : data
            )
          : [];
        this.setState({ reports: tempRecords });
      }
      //
    });

    // console.log(mosquitto);
  };
  unSubscribe = () => {
    var successTopic = this.state.device_id + "/device/app";

    var mosquitto = mqtt.connect("wss://cylenium.io", {
      port: 8083,
      username: "mqttdonald",
      password: "mqttdonald",
      clean: true,
      retain: false,
    });
    mosquitto.unsubscribe(successTopic, function (err) {});
    mosquitto.end();
  };

  fetchParentalControl = async () => {
    const response = await settingsMethods.getParentalSettings({
      device_id: this.state.device_id,
    });

    if (response.data.statusCode === 200) {
      this.setState({
        parentalSettings: response.data.result[0],
        dayRule: response.data.result[0].day_rule,
        keywords: response.data.result[0].keyword,
        startTime: response.data.result[0].start_time,
        endTime: response.data.result[0].end_time,
        devicesList: response.data.result[0].devices,
      });

      this.setValues();
    }
    if (
      response.data.statusCode === 401 &&
      response.data.message == "Session expires. Please login again"
    ) {
      this.setState({
        showLogout: true,
      });
    }
    if (
      response.data.statusCode === 401 &&
      response.data.message != "Session expires. Please login again"
    ) {
      this.setState({
        open: true,
        message: response.data.message,
        loader: false,
      });
    }
  };

  handleChange = (event, isChecked) => {
    const daysList = this.state.daysList;

    daysList.forEach((day) => {
      if (day.name === event.target.value) {
        day.isChecked = isChecked;
      }
    });

    this.setState({
      daysList: daysList,
    });
    console.log(daysList);
  };
  fetchScansReport = async () => {
    const response = await settingsMethods.fetchScansReport({
      device_id: this.state.device_id,
    });

    if (response.data.statusCode === 200) {
      const settings = [
        {
          id: 1,
          value: "openvas_autoscan_flag",
          name: "Auto Scan",
          isChecked: response.data.result?.auto_scan[0]
            ? response.data?.result?.auto_scan[0].openvas_autoscan_flag
            : 0,
          isDisabled: false,
        },
      ];

      this.setState({
        settings: settings,
        quarantine_status:
          response.data.result?.auto_scan[0]?.openvas_autoscan_value,
        reports: response.data.result?.reports,
        devicesList: response.data.result.iot_devices,
        records: response.data.result,
      });
    }

    if (
      response.data.statusCode === 401 &&
      response.data.message == "Session expires. Please login again"
    ) {
      this.setState({
        showLogout: true,
      });
    }
    if (
      response.data.statusCode === 401 &&
      response.data.message != "Session expires. Please login again"
    ) {
      this.setState({
        open: true,
        message: response.data.message,
        loader: false,
      });
    }
  };
  createScan = async () => {
    const days = [];
    this.state.daysList?.map((data, index) => {
      if (data.isChecked) {
        days.push({ day: data.name.toLowerCase() });
      }
    });
    const payload =
      this.state.scan_option === "monthly"
        ? {
            scan_config: this.state.scan_config,
            scan_name: this.state.scan_name,

            schedule: {
              run_from: moment(this.state.from_date).format("X") * 1000,
              run_until: moment(this.state.to_date).format("X") * 1000,
              recurrence: {
                pattern:
                  this.state.monthly_radio === "on_day"
                    ? {
                        type:
                          this.state.scanType !== "quick_scan"
                            ? this.state.scan_option === "every_weekday"
                              ? "weekly"
                              : this.state.scan_option
                            : "once",
                        interval: this.state.repeat_every,
                        days_of_month: [this.state.days_of_month],
                      }
                    : {
                        type:
                          this.state.scanType !== "quick_scan"
                            ? this.state.scan_option === "every_weekday"
                              ? "weekly"
                              : this.state.scan_option
                            : "once",
                        interval: this.state.repeat_every,
                        days_of_week: [
                          {
                            day: this.state.selected_day?.toLowerCase(),
                            occurrence: this.state.week_occurence,
                          },
                        ],
                        months_of_year: this.state.month,
                      },
              },
            },
            devices: this.state.devicesList,
            device_id: this.state.device_id,
          }
        : this.state.scan_option === "yearly"
        ? {
            scan_config: this.state.scan_config,
            scan_name: this.state.scan_name,

            schedule: {
              run_from: moment(this.state.from_date).format("X") * 1000,
              run_until: moment(this.state.to_date).format("X") * 1000,
              recurrence: {
                pattern:
                  this.state.monthly_radio === "on_day"
                    ? {
                        type:
                          this.state.scanType !== "quick_scan"
                            ? this.state.scan_option === "every_weekday"
                              ? "weekly"
                              : this.state.scan_option
                            : "once",
                        interval: this.state.repeat_every,
                        days_of_month: [this.state.days_of_month],
                        months_of_year: this.state.month,
                      }
                    : {
                        type:
                          this.state.scanType !== "quick_scan"
                            ? this.state.scan_option === "every_weekday"
                              ? "weekly"
                              : this.state.scan_option
                            : "once",
                        interval: this.state.repeat_every,
                        days_of_week: [
                          {
                            day: this.state.selected_day?.toLowerCase(),
                            occurrence: this.state.week_occurence,
                          },
                        ],
                        months_of_year: this.state.month,
                      },
              },
            },
            devices: this.state.devicesList,
            device_id: this.state.device_id,
          }
        : {
            scan_config: this.state.scan_config,
            scan_name: this.state.scan_name,

            schedule: {
              run_from: moment(this.state.from_date).format("X") * 1000,
              run_until: moment(this.state.to_date).format("X") * 1000,
              recurrence: {
                pattern: {
                  type:
                    this.state.scanType !== "quick_scan"
                      ? this.state.scan_option === "every_weekday"
                        ? "weekly"
                        : this.state.scan_option
                      : "once",
                  interval: this.state.repeat_every,
                  days_of_week:
                    this.state.scan_option === "weekly" ||
                    this.state.scan_option === "every_weekday"
                      ? days
                      : [],
                },
              },
            },
            devices: this.state.devicesList,
            device_id: this.state.device_id,
          };
    console.log(payload);
    if (!this.state.devicesList?.length) {
      toast.error("Select Device for scan");
    } else if (!this.state.scan_name?.trim().length)
      toast.error("Scan name can not be empty");
    else {
      const response = await settingsMethods.createScan(payload);
      if (response.data.statusCode == 200) {
        this.setState({
          scan_name: "",
          scan_config: "full_and_fast",

          daysList: [
            { id: 1, name: "Monday", isChecked: true },
            { id: 2, name: "Tuesday", isChecked: false },
            { id: 3, name: "Wednesday", isChecked: false },
            { id: 4, name: "Thursday", isChecked: false },
            { id: 5, name: "Friday", isChecked: false },
            { id: 6, name: "Saturday", isChecked: false },
            { id: 7, name: "Sunday", isChecked: false },
          ],
          run_from: Date.now(),
          run_until: Date.now(),
          selected_day: "Monday",
          scan_option: "daily",
          repeat_every: "1",
          scanType: "quick_scan",
        });
        // toast.success(response.data.message);
        this.fetchScansReport();
        toast.success(response.data.message);
      } else toast.error(response.data.message);

      if (
        response.data.statusCode === 401 &&
        response.data.message == "Session expires. Please login again"
      ) {
        this.setState({
          showLogout: true,
        });
      }
      if (
        response.data.statusCode === 401 &&
        response.data.message != "Session expires. Please login again"
      ) {
        this.setState({
          open: true,
          message: response.data.message,
          loader: false,
        });
      }
    }
  };
  getScanReports = async (unique_id) => {
    const response = await settingsMethods.getScanReports({ unique_id });
    // console.log(response);
    if (response.data.statusCode == 200) {
      this.setState({ scan_name: "", scan_config: "full_and_fast" });
      window.open(response.data.result?.url);
      // console.log(response.data.result?.url)

      toast.success(response.data.message);
    } else toast.error(response.data.message);

    if (
      response.data.statusCode === 401 &&
      response.data.message == "Session expires. Please login again"
    ) {
      this.setState({
        showLogout: true,
      });
    }
    if (
      response.data.statusCode === 401 &&
      response.data.message != "Session expires. Please login again"
    ) {
      this.setState({
        open: true,
        message: response.data.message,
        loader: false,
      });
    }
  };
  deleteVulnerabilityScan = async (unique_id) => {
    const response = await settingsMethods.deleteVulnerabilityScan(unique_id);

    if (response.data.statusCode == 200) {
      this.setState({ scan_name: "", scan_config: "full_and_fast" });
      // window.open(response.data.result?.url);
      this.fetchScansReport();
      // console.log(response.data.result?.url)
      toast.success(response.data.message);
    } else toast.error(response.data.message);

    if (
      response.data.statusCode === 401 &&
      response.data.message == "Session expires. Please login again"
    ) {
      this.setState({
        showLogout: true,
      });
    }
    if (
      response.data.statusCode === 401 &&
      response.data.message != "Session expires. Please login again"
    ) {
      this.setState({
        open: true,
        message: response.data.message,
        loader: false,
      });
    }
  };

  logoutUser = () => {
    window.sessionStorage.clear();
    this.props.history.replace({
      pathname: "/",
    });
  };

  async setValues() {
    let getSettings = this.state.getSettings;
    let settings = this.state.settings;
    let devices = this.state.devices;
    let securities = this.state.securities;
    let role_id = JSON.parse(
      window.sessionStorage.getItem("ConnectedDevice")
    ).role_id;

    Object.keys(getSettings).forEach((key) => {
      settings.forEach((setting) => {
        if (setting.value === key) {
          setting.isChecked = getSettings[key] === 0 ? false : true;
        }
        setting.isDisabled = role_id === 2 ? true : false;
      });

      devices.forEach((device) => {
        if (device.value === key) {
          device.isChecked = getSettings[key] === 0 ? false : true;
        }
        if (device.id !== 6) {
          device.isDisabled = role_id === 2 ? true : false;
        }
      });

      securities.forEach((security) => {
        if (security.value === key) {
          security.isChecked = getSettings[key] === 0 ? false : true;
        }
        security.isDisabled = role_id === 2 ? true : false;
      });
    });

    //
    this.setState({
      connect_pref: this.state.getSettings.connect_pref,
      wifi_profile: this.state.getSettings.wifi_profile,
      wifi_name_ssid: this.state.getSettings.wifi_name_ssid,
      settings: settings,
      devices: devices,
      securities: securities,
    });
    //
  }

  handleCheckChildElement = async (event) => {
    let settings = this.state.settings;
    let devices = this.state.devices;
    let securities = this.state.securities;
    //
    //

    settings.forEach((setting) => {
      if (setting.value === event.target.value)
        setting.isChecked = event.target.checked;
    });

    devices.forEach((device) => {
      if (device.value === event.target.value)
        device.isChecked = event.target.checked;
    });

    securities.forEach((security) => {
      if (security.value === event.target.value)
        security.isChecked = event.target.checked;
    });
    if (event.target.value === "protect_mode") {
      securities[0].isChecked = event.target.checked;
      securities[1].isChecked = !event.target.checked;
    }
    if (event.target.value === "monitoring_mode") {
      securities[1].isChecked = event.target.checked;
      securities[0].isChecked = !event.target.checked;
    }

    await this.setState({
      settings: settings,
      devices: devices,
      securities: securities,
      type: event.target.value,
      keyValue: event.target.checked ? 1 : 0,
    });

    this.updateSettingData();
  };

  handleConnPrefChange = async (event) => {
    await this.setState({
      connect_pref: event.target.value,
      type: "connect_pref",
      keyValue: event.target.value,
    });

    this.updateSettingData();
  };

  handleWifiProfileChange = async (event) => {
    this.setState({ quarantine_status: event.target.value });

    var updatedData = {
      // setting_id: this.state.setting_id,
      device_id: this.state.device_id,
      type: "openvas_autoscan_value",
      openvas_autoscan_value: event.target.value,
    };

    const response = await settingsMethods.updateSettings(updatedData);
    //
    if (response.data.statusCode === 200) {
      // this.setState({
      //   getSettings: response.data.result[0],
      //   setting_id: response.data.result[0].setting_id,
      // });
      if (response.data.result[0].remote_mgt === 0) {
        this.setState({ open: true, message: response.data.message });
      }
      // this.setValues();
    }
    toast.success(response.data.message);
    this.fetchScansReport();
  };
  selectScanConfig = async (event) => {
    this.setState({ scan_config: event.target.value });
  };

  updateSettingData() {
    const combinedObject = [
      ...this.state.settings,
      ...this.state.devices,
      ...this.state.securities,
    ];

    var updatedData = {
      setting_id: this.state.getSettings.setting_id,
      device_id: this.state.device_id,
      connect_pref: this.state.connect_pref,
      wifi_profile: this.state.wifi_profile,
    };

    combinedObject.forEach((element) => {
      updatedData[element.value] = element.isChecked === false ? 0 : 1;
    });

    this.updateSettings();
  }

  async updateSettings() {
    var updatedData = {
      setting_id: this.state.setting_id,
      device_id: this.state.device_id,
      type: this.state.type,
      [this.state.type]: this.state.keyValue,
    };

    const response = await settingsMethods.updateSettings(updatedData);
    //
    if (response.data.statusCode === 200) {
      // this.setState({
      //   getSettings: response.data.result[0],
      //   setting_id: response.data.result[0].setting_id,
      // });
      toast.success(response.data.message);
      if (response.data.result[0].remote_mgt === 0) {
        this.setState({ open: true, message: response.data.message });
      }
      this.setValues();
    }
    this.fetchScansReport();
  }

  handleOpen = (type) => {
    console.log(type);
    this.setState({
      editModal: true,
      updateType: type,
      errorModal: "",
    });

    if (type === "SSID") {
      this.setState({
        modalTitle: "SSID",
        modalLabel: "Enter new SSID",
      });
    }

    if (type === "PASSWORD") {
      //  Set Password
      this.setState({
        modalTitle: "Password",
        modalLabel: "Enter new password",
      });
    }
    if (type === "CONNECT") {
      this.setState({
        modalTitle: "CONNECT TO NETWORK",
        modalLabel: "Network",
      });
    }
  };

  handleClose = () => {
    this.setState({ editModal: false });
  };

  handleUpdateValue = async (event) => {
    await this.setState({
      [event.target.name]: event.target.value,
    });

    if (this.state.updateType === "PASSWORD") {
      if (this.state.password !== this.state.changePassword) {
        this.setState({
          errorModal: "Password and Confirm Password should be same",
        });
      } else {
        this.setState({
          errorModal: "",
        });
      }
    }
  };

  updateTextSettingData = async () => {
    if (this.state.updateType === "SSID") {
      if (this.state.wifi_name_ssid === "") {
        this.setState({
          errorModal: "Please enter the SSID",
        });
      } else {
        this.setState({
          errorModal: "",
          loader: true,
        });

        var updateObject = {
          device_id: this.state.device_id,
          type: "wifi_name_ssid",
          value: this.state.wifi_name_ssid,
        };

        const response = await settingsMethods.updateSSID(updateObject);

        if (response.data.statusCode === 200) {
          this.updateSettingData();

          this.resetValues();
        }
      }
    }

    if (this.state.updateType === "PASSWORD") {
      if (this.state.password === "" || this.state.changePassword === "") {
        this.setState({
          errorModal: "Please enter the password",
          password: "",
          changePassword: "",
        });
      } else if (
        this.state.password.length < 10 ||
        this.state.changePassword.length < 10
      ) {
        this.setState({
          errorModal: "Minimum 10 characters require to set password",
          password: "",
          changePassword: "",
        });
      } else {
        this.setState({
          errorModal: "",
          loader: true,
        });

        updateObject = {
          device_id: this.state.device_id,
          type: "wifi_password",
          value: this.state.changePassword,
        };

        const response = await settingsMethods.updateSSID(updateObject);

        if (response.data.statusCode === 200) {
          this.updateSettingData();
          this.resetValues();
        }
      }
    }
  };

  togglePassword() {
    var pssInput = document.getElementById("toggle");
    var passIcon = document.getElementById("toggleIcon");

    if (pssInput.type === "password") {
      pssInput.type = "text";
      passIcon.className = "fa fa-eye";
    } else {
      pssInput.type = "password";
      passIcon.className = "fa fa-eye-slash";
    }
  }

  resetValues = () => {
    this.setState({
      editModal: false,
      modalTitle: "SSID",
      modalLabel: "Enter new SSID",
      errorModal: "",
      updateType: "SSID",
      password: "",
      changePassword: "",
      modalPasswordLabel: "Enter confirm password",
      loader: false,
    });
  };
  handleOpenList = async () => {
    if (true) {
      const response = await settingsMethods.getAllIOTDevices({
        device_id: this.state.device_id,
      });
      console.log(response);
      if (response.data.statusCode === 200) {
        this.setState({
          openListModal: true,
          selectDevicesList: response.data.result.devices,
        });
      }
    } else {
      this.setState({
        openListModal: true,
      });
    }
  };

  handleClose = () => {
    this.setState({ openListModal: false });
  };

  handleClickSelection = (event) => {
    const selectedDevicesList = this.state.selectDevicesList;
    let updatedDevicesList = [];

    selectedDevicesList.forEach((device) => {
      if (device.name === event.target.value) {
        device.isSelected = JSON.stringify(event.target.checked);
      }

      if (device.isSelected === "true") {
        updatedDevicesList.push(device);
      }
    });

    this.setState({
      selectDevicesList: selectedDevicesList,
      devicesList: updatedDevicesList,
    });
  };

  updateSelections = async () => {
    await this.setState({
      openListModal: false,
      selectDevicesList: this.state.selectDevicesList,
      isUpdatedList: 1,
    });
  };
  changeScheduleTime = (event) => {
    this.setState({
      scanType: event.target.value,
    });
  };
  changTime = (event) => {
    this.setState({
      time: event.target.value,
    });
  };

  handleText = (event) => {
    this.setState({ scan_name: event.target.value });
  };
  handleRecurrence = (event) => {
    this.setState({
      scan_option: event.target.value,
    });
    if (event.target.value === "monthly" || event.target.value === "yearly") {
      this.setState({ monthly_radio: "on_day" });
    }
    if (event.target.value === "weekly") {
      this.setState({
        daysList: [
          { id: 1, name: "Monday", isChecked: true },
          { id: 2, name: "Tuesday", isChecked: false },
          { id: 3, name: "Wednesday", isChecked: false },
          { id: 4, name: "Thursday", isChecked: false },
          { id: 5, name: "Friday", isChecked: false },
          { id: 6, name: "Saturday", isChecked: false },
          { id: 7, name: "Sunday", isChecked: false },
        ],
      });
    }
    if (event.target.value === "every_weekday")
      this.setState({
        daysList: [
          { id: 1, name: "Monday", isChecked: true },
          { id: 2, name: "Tuesday", isChecked: true },
          { id: 3, name: "Wednesday", isChecked: true },
          { id: 4, name: "Thursday", isChecked: true },
          { id: 5, name: "Friday", isChecked: true },
          { id: 6, name: "Saturday", isChecked: false },
          { id: 7, name: "Sunday", isChecked: false },
        ],
      });
  };
  render() {
    console.log(this.state, Object.keys(this.state?.records));
    return (
      <MuiPickersUtilsProvider utils={Moment}>
        <div className="routing-main-wrap">
          <div className="page-head">
            <h3>Vulnerability Scanning</h3>
            {this.state.connectedDevices != null ? (
              <h3>{this.state.connectedDevices}</h3>
            ) : null}

            <ToggleSideNav batteryRefresh="false" />
          </div>
          {Object.keys(this.state.records)?.length > 0 ? (
            <div className="rout-body">
              <div className="row">
                <div className="col-sm-12">
                  <div className="settings-device-details">
                    <div className="black-card settings-card mr-b20">
                      <div className="d-d-card-head">
                        <h4>Scan</h4>
                        <CustomToolTip message={AutoScan} />
                      </div>
                      <div className="device-info-wrap mr-t10">
                        <div className="row">
                          {this.state.settings.map((setting) => {
                            return (
                              <CheckBox
                                key={setting.id}
                                onChange={this.handleCheckChildElement}
                                {...setting}
                              />
                            );
                          })}

                          {this.state.settings[0].isChecked ? (
                            <div className="col-6 col-sm-auto">
                              <div className="d-info-card">
                                <label>Quarantine status</label>
                                <div
                                  className="cus-select"
                                  style={{ display: "flex" }}
                                >
                                  <select
                                    name="quarantine_status"
                                    onChange={(e) =>
                                      this.handleWifiProfileChange(e)
                                    }
                                    value={this.state.quarantine_status}
                                    style={{
                                      display: "flex",
                                      minWidth: "80px",
                                      padding: "0px 3px",
                                    }}
                                  >
                                    <option value="high" name="high" id="1">
                                      High
                                    </option>
                                    <option value="medium" name="medium" id="2">
                                      Medium
                                    </option>
                                    {/* <option value="high" name="high" id="1">
                                      High
                                    </option> */}
                                    <option value="low" name="low" id="3">
                                      Low
                                    </option>
                                    <option value="none" name="none" id="5">
                                      None
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          {/* <div className="col-6 col-sm-auto">
                        <div className="d-info-card">
                          <label>Connection Preference</label>
                          <div className="cus-select">
                            <select
                              name="connect_pref"
                              value={this.state.connect_pref}
                              onChange={(e) => this.handleConnPrefChange(e)}
                              style={{
                                display: "flex",
                                minWidth: "110px",
                                padding: "0px 3px",
                              }}
                            >
                              <option
                                value="Auto Connect"
                                name="Auto Connect"
                                id="1"
                              >
                                Auto Connect
                              </option>
                              <option value="Manual" id="2" name="Manual">
                                Manual
                              </option>
                            </select>
                          </div>
                        </div>
                      </div> */}
                        </div>
                      </div>
                      {this.state.settings[3]?.isChecked ? (
                        <div>
                          <div className="device-info-wrap ">
                            <div className="row">
                              <div className="col-1 col-md-1 ">
                                <div className="d-info-card">
                                  <label>Network</label>
                                </div>
                              </div>
                              <div className="col-6 col-md-7 pd-l50">
                                <div className="d-info-card">
                                  <label>Network Name</label>
                                </div>
                              </div>
                              <div className="col-3 col-md-3 ">
                                <div className="d-info-card">
                                  <label>
                                    <h4>Network Status</h4>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {this.state.networks?.length ? (
                            this.state.networks.map((data, index) => (
                              <div key={index} className="device-info-wrap ">
                                <div className="row">
                                  <div className="col-1 col-md-1 ">
                                    <div className="d-info-card">
                                      <WifiIcon />
                                    </div>
                                  </div>
                                  <div className="col-6 col-md-7 pd-l50">
                                    <div className="d-info-card">
                                      <label>
                                        <h4 style={{ color: "#6BC4E2" }}>
                                          {data.name}
                                        </h4>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-3 col-md-3 ">
                                    <div className="d-info-card">
                                      <label
                                        onClick={() =>
                                          this.handleOpen("CONNECT")
                                        }
                                      >
                                        <h4 style={{ color: "#6BC4E2" }}>
                                          {data.status}
                                        </h4>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="col-12 col-md-12  text-center">
                              <div className="d-info-card">
                                <label>No Network Available</label>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : null}
                    </div>
                    <div className="black-card d-d-card">
                      <div className="controls-card">
                        <div className="c-title">
                          <h4>Select the Device</h4>
                        </div>
                        <div className="net-option mr-t20">
                          {this.state.devicesList.length > 0 ? (
                            <div className="row">
                              {this.state.devicesList.map((device, index) => (
                                <div className="col-sm-4" key={index}>
                                  <h5>
                                    <svg
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M2.07491 9.1005C2.04983 9.12559 2.02486 9.15075 2 9.17598L3.41426 10.5902C3.43908 10.565 3.46404 10.5398 3.48913 10.5147C8.15033 5.85352 15.6921 5.82856 20.3842 10.4399L21.7984 9.02559C16.3253 3.63328 7.51717 3.65825 2.07491 9.1005ZM4.75295 11.9289C8.6582 8.02369 14.9898 8.02369 18.8951 11.9289L17.4809 13.3431C14.3567 10.219 9.29136 10.219 6.16717 13.3431L4.75295 11.9289ZM16.0667 14.7574C13.7235 12.4142 9.92453 12.4142 7.58138 14.7574L8.99559 16.1716C10.5577 14.6095 13.0904 14.6095 14.6524 16.1716L16.0667 14.7574ZM13.2382 17.5858C12.4572 16.8047 11.1909 16.8047 10.4098 17.5858L11.824 19L13.2382 17.5858Z"
                                        fill="white"
                                      />
                                    </svg>
                                    {device.name}
                                  </h5>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="text-center registered-title mr-b20 mr-t20">
                              <h6
                                style={{ color: "#7C8F97", fontSize: "12px" }}
                              >
                                No Devices Selected
                              </h6>
                            </div>
                          )}

                          <button
                            type="button"
                            className="trans-red-btn mr-t20"
                            onClick={this.handleOpenList}
                          >
                            Add/Remove device
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="black-card settings-card mr-b20">
                      <div className="d-d-card-head">
                        <h4>Scan Details</h4>
                      </div>
                      <div className="device-info-wrap mr-t10">
                        <div className="row">
                          <div className="col-6 col-sm-auto">
                            <div className="d-info-card">
                              <label>Scan Name</label>
                              <div className="cus_trial_toggle">
                                <TextField
                                  placeholder="Enter Scan Name"
                                  onChange={(event) => {
                                    this.handleText(event);
                                    // this.setState({
                                    //   scan_name: event.target.value,
                                    // });
                                  }}
                                  value={this.state.scan_name}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-6 col-sm-auto">
                            <div className="d-info-card">
                              <label>Scan configuration</label>
                              <div
                                className="cus-select"
                                style={{ display: "flex" }}
                              >
                                <select
                                  name="scan_config"
                                  onChange={(e) => this.selectScanConfig(e)}
                                  value={this.state.scan_config}
                                  style={{
                                    display: "flex",
                                    minWidth: "150px",
                                    // padding: "0px 3px",
                                  }}
                                >
                                  <option
                                    value="full_and_fast"
                                    name="high"
                                    id="1"
                                  >
                                    Full and Fast{" "}
                                  </option>
                                  <option
                                    value="discovery"
                                    name="discovery"
                                    id="2"
                                  >
                                    Discovery
                                  </option>
                                  <option value="base" name="base" id="1">
                                    Base
                                  </option>
                                  {/* <option
                                    value="host_discovery"
                                    name="host_discovery"
                                    id="3"
                                  >
                                    Host Discovery
                                  </option> */}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="black-card d-d-card">
                      <div className="controls-card ">
                        <div className="c-title mr-b20">
                          <h4> Create Schedule</h4>
                        </div>
                        <div className="web-input mr-b10 memebr_head">
                          <h4 className="mr-t10 mr-b10">Scan Type</h4>

                          <div className="col-sm-6 col-md-12 mr-l10">
                            <RadioGroup
                              aria-label="scheduleTime"
                              name="scheduleTime"
                              value={this.state.scanType}
                              onChange={this.changeScheduleTime}
                              row
                            >
                              <FormControlLabel
                                value="quick_scan"
                                control={<Radio />}
                                label="Quick Scan"
                                checked={this.state.scanType === "quick_scan"}
                              />
                              <FormControlLabel
                                value="schedule_scan"
                                control={<Radio />}
                                label="Schedule Scan"
                                checked={
                                  this.state.scanType === "schedule_scan"
                                }
                              />
                            </RadioGroup>
                          </div>
                          {this.state.scanType === "schedule_scan" ? (
                            <div className="row1 ml-3">
                              {/* <div className="col-md-4 col-sm-12 col-sm-auto">
                                <div className="d-info-card">
                                <label>Start From</label>
                                <div className="cus_trial_toggle">
                                <MuiPickersUtilsProvider
                                      utils={MomentUtils}
                                      >
                                      <KeyboardDateTimePicker
                                        //disableToolbar
                                        variant="inline"
                                        className="date"
                                        format="MMM,DD yyyy HH:mm"
                                        margin="normal"
                                        id="date-picker-inline"
                                        value={this.state.run_until}
                                        onChange={(value) =>
                                          this.setState({ run_until: value })
                                        }
                                        KeyboardButtonProps={{
                                          "aria-label": "change date",
                                        }}
                                      />
                                    </MuiPickersUtilsProvider>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-12 col-sm-auto">
                                <div className="d-info-card">
                                  <label>Run Until</label>
                                  <div className="cus_trial_toggle">
                                    <MuiPickersUtilsProvider
                                      utils={MomentUtils}
                                    >
                                      <KeyboardDateTimePicker
                                        //disableToolbar
                                        variant="inline"
                                        className="date"
                                        format="MMM,DD yyyy HH:mm"
                                        margin="normal"
                                        id="date-picker-inline"
                                        value={this.state.run_until}
                                        onChange={(value) =>
                                          this.setState({ run_until: value })
                                        }
                                        KeyboardButtonProps={{
                                          "aria-label": "change date",
                                        }}
                                      />
                                    </MuiPickersUtilsProvider>
                                    </div>
                                    </div>
                                  </div> */}
                              <div
                                className="col-md-12 col-sm-12 col-sm-auto "
                                style={{
                                  padding: "10px",
                                }}
                              >
                                <div className="d-info-card">
                                  <label>Recurrence</label>
                                  <div className="cus_trial_toggle">
                                    <Select
                                      className="custom-select1"
                                      value={this.state.scan_option}
                                      onChange={this.handleRecurrence}
                                    >
                                      {scan_option.map((option) => (
                                        <MenuItem
                                          key={option.value}
                                          value={option.value}
                                        >
                                          {option.label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </div>
                                </div>
                              </div>
                              {this.state.scan_option === "daily" ? (
                                <div className="row mt-3 pl-2">
                                  <div className="col-md-4 col-sm-12 col-sm-auto">
                                    <div className="d-info-card">
                                      <label>From</label>
                                      <div className="cus_trial_toggle">
                                        <MuiPickersUtilsProvider
                                          utils={MomentUtils}
                                        >
                                          <KeyboardDateTimePicker
                                            //disableToolbar
                                            variant="inline"
                                            className="date"
                                            format="MMM, DD yyyy HH:mm"
                                            showTodayButton={true}
                                            autoOk={true}
                                            margin="normal"
                                            id="date-picker-inline"
                                            value={this.state.from_date}
                                            onChange={(value) =>
                                              this.setState({
                                                from_date: value,
                                                to_date: value,
                                              })
                                            }
                                            KeyboardButtonProps={{
                                              "aria-label": "change date",
                                            }}
                                          />
                                        </MuiPickersUtilsProvider>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-12 col-sm-auto">
                                    <div className="d-info-card">
                                      <label>To</label>
                                      <div className="cus_trial_toggle">
                                        <MuiPickersUtilsProvider
                                          utils={MomentUtils}
                                        >
                                          <KeyboardDateTimePicker
                                            //disableToolbar
                                            variant="inline"
                                            className="date"
                                            format="MMM, DD yyyy HH:mm"
                                            showTodayButton={true}
                                            autoOk={true}
                                            margin="normal"
                                            id="date-picker-inline"
                                            value={this.state.to_date}
                                            onChange={(value) =>
                                              this.setState({ to_date: value })
                                            }
                                            KeyboardButtonProps={{
                                              "aria-label": "change date",
                                            }}
                                          />
                                        </MuiPickersUtilsProvider>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 col-sm-12 col-sm-auto ">
                                    <div className="d-info-card">
                                      <label>Repeat Every</label>
                                      <div className="cus_trial_toggle">
                                        <TextField
                                          placeholder="Repeat Every"
                                          type="number"
                                          InputProps={{
                                            inputProps: {
                                              max: 2,
                                              min: 1,
                                            },
                                          }}
                                          onChange={(event) => {
                                            this.setState({
                                              repeat_every: event.target.value,
                                            });
                                          }}
                                          value={this.state.repeat_every}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="col-md-12 col-sm-12 col-sm-auto ">
                                    <div className="d-info-card">
                                      <div className="cus_trial_toggle">
                                        {this.state.daysList.map(
                                          (element, i) => (
                                            <label
                                              className="checkbox-label"
                                              key={i}
                                            >
                                              <Checkbox
                                                key={element.id}
                                                value={element.name}
                                                onChange={this.handleChange}
                                                checked={element.isChecked}
                                                inputProps={{
                                                  "aria-label":
                                                    "primary checkbox",
                                                }}
                                              />
                                              <span className="mr-r5"></span>
                                              {element.name}
                                            </label>
                                          )
                                        )}{" "}
                                      </div>
                                    </div>
                                  </div> */}
                                </div>
                              ) : null}
                              {this.state.scan_option === "weekly" ? (
                                <div className="row mt-3 pl-2">
                                  <div className="col-md-4 col-sm-12 col-sm-auto">
                                    <div className="d-info-card">
                                      <label>From</label>
                                      <div className="cus_trial_toggle">
                                        <MuiPickersUtilsProvider
                                          utils={MomentUtils}
                                        >
                                          <KeyboardDateTimePicker
                                            //disableToolbar
                                            variant="inline"
                                            className="date"
                                            format="MMM, DD yyyy HH:mm"
                                            showTodayButton={true}
                                            autoOk={true}
                                            margin="normal"
                                            id="date-picker-inline"
                                            value={this.state.from_date}
                                            onChange={(value) =>
                                              this.setState({
                                                from_date: value,
                                              })
                                            }
                                            KeyboardButtonProps={{
                                              "aria-label": "change date",
                                            }}
                                          />
                                        </MuiPickersUtilsProvider>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-12 col-sm-auto">
                                    <div className="d-info-card">
                                      <label>To</label>
                                      <div className="cus_trial_toggle">
                                        <MuiPickersUtilsProvider
                                          utils={MomentUtils}
                                        >
                                          <KeyboardDateTimePicker
                                            //disableToolbar
                                            variant="inline"
                                            className="date"
                                            format="MMM, DD yyyy HH:mm"
                                            showTodayButton={true}
                                            autoOk={true}
                                            margin="normal"
                                            id="date-picker-inline"
                                            value={this.state.to_date}
                                            onChange={(value) =>
                                              this.setState({ to_date: value })
                                            }
                                            KeyboardButtonProps={{
                                              "aria-label": "change date",
                                            }}
                                          />
                                        </MuiPickersUtilsProvider>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 col-sm-12 col-sm-auto ">
                                    <div className="d-info-card">
                                      <label>Repeat Every</label>
                                      <div className="cus_trial_toggle">
                                        <TextField
                                          placeholder="Repeat Every"
                                          type="number"
                                          InputProps={{
                                            inputProps: {
                                              max: 2,
                                              min: 1,
                                            },
                                          }}
                                          onChange={(event) => {
                                            this.setState({
                                              repeat_every: event.target.value,
                                            });
                                          }}
                                          value={this.state.repeat_every}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12 col-sm-12 col-sm-auto ">
                                    <div className="d-info-card">
                                      {this.state.daysList.map((element, i) => (
                                        <label
                                          className="checkbox-label"
                                          key={i}
                                        >
                                          <Checkbox
                                            key={element.id}
                                            value={element.name}
                                            onChange={this.handleChange}
                                            checked={element.isChecked}
                                            inputProps={{
                                              "aria-label": "primary checkbox",
                                            }}
                                          />
                                          <span className="mr-r5"></span>
                                          {element.name}
                                        </label>
                                      ))}{" "}
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              {this.state.scan_option === "every_weekday" ? (
                                <div className="row mt-3 pl-2">
                                  <div className="col-md-4 col-sm-12 col-sm-auto">
                                    <div className="d-info-card">
                                      <label>From</label>
                                      <div className="cus_trial_toggle">
                                        <MuiPickersUtilsProvider
                                          utils={MomentUtils}
                                          minDate={new Date()} // disabling past dates
                                        >
                                          <KeyboardDateTimePicker
                                            // //disableToolbar
                                            variant="inline"
                                            className="date"
                                            format="MMM, DD yyyy HH:mm"
                                            showTodayButton={true}
                                            autoOk={true}
                                            margin="normal"
                                            minDate={new Date()} // disabling past dates
                                            id="date-picker-inline"
                                            value={this.state.from_date}
                                            onChange={(value) =>
                                              this.setState({
                                                from_date: value,
                                              })
                                            }
                                            KeyboardButtonProps={{
                                              "aria-label": "change date",
                                            }}
                                          />
                                        </MuiPickersUtilsProvider>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-12 col-sm-auto">
                                    <div className="d-info-card">
                                      <label>To</label>
                                      <div className="cus_trial_toggle">
                                        <MuiPickersUtilsProvider
                                          utils={MomentUtils}
                                        >
                                          <KeyboardDateTimePicker
                                            //disableToolbar
                                            variant="inline"
                                            className="date"
                                            minDate={this.state.from_date} // disabling past dates
                                            disablePast
                                            format="MMM, DD yyyy HH:mm"
                                            showTodayButton={true}
                                            autoOk={true}
                                            margin="normal"
                                            id="date-picker-inline"
                                            value={this.state.to_date}
                                            onChange={(value) =>
                                              this.setState({ to_date: value })
                                            }
                                            KeyboardButtonProps={{
                                              "aria-label": "change date",
                                            }}
                                          />
                                        </MuiPickersUtilsProvider>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 col-sm-12 col-sm-auto ">
                                    <div className="d-info-card">
                                      <label>Repeat Every</label>
                                      <div className="cus_trial_toggle">
                                        <TextField
                                          placeholder="Repeat Every"
                                          type="number"
                                          InputProps={{
                                            inputProps: {
                                              max: 2,
                                              min: 1,
                                            },
                                          }}
                                          onChange={(event) => {
                                            this.setState({
                                              repeat_every: event.target.value,
                                            });
                                          }}
                                          value={this.state.repeat_every}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12 col-sm-12 col-sm-auto ">
                                    <div className="d-info-card">
                                      {this.state.daysList.map((element, i) => (
                                        <label
                                          className="checkbox-label"
                                          key={i}
                                        >
                                          <Checkbox
                                            key={element.id}
                                            value={element.name}
                                            onChange={this.handleChange}
                                            checked={element.isChecked}
                                            inputProps={{
                                              "aria-label": "primary checkbox",
                                            }}
                                          />
                                          <span className="mr-r5"></span>
                                          {element.name}
                                        </label>
                                      ))}{" "}
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.scan_option === "monthly" ? (
                                <div className="row mt-3 pl-2">
                                  <div className="col-md-4 col-sm-12 col-sm-auto">
                                    <div className="d-info-card">
                                      <label>From</label>
                                      <div className="cus_trial_toggle">
                                        <MuiPickersUtilsProvider
                                          utils={MomentUtils}
                                        >
                                          <KeyboardDateTimePicker
                                            //disableToolbar
                                            variant="inline"
                                            className="date"
                                            format="MMM, DD yyyy HH:mm"
                                            showTodayButton={true}
                                            autoOk={true}
                                            margin="normal"
                                            id="date-picker-inline"
                                            value={this.state.from_date}
                                            onChange={(value) =>
                                              this.setState({
                                                from_date: value,
                                              })
                                            }
                                            KeyboardButtonProps={{
                                              "aria-label": "change date",
                                            }}
                                          />
                                        </MuiPickersUtilsProvider>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-12 col-sm-auto">
                                    <div className="d-info-card">
                                      <label>To</label>
                                      <div className="cus_trial_toggle">
                                        <MuiPickersUtilsProvider
                                          utils={MomentUtils}
                                        >
                                          <KeyboardDateTimePicker
                                            //disableToolbar
                                            variant="inline"
                                            className="date"
                                            format="MMM, DD yyyy HH:mm"
                                            showTodayButton={true}
                                            autoOk={true}
                                            margin="normal"
                                            id="date-picker-inline"
                                            value={this.state.to_date}
                                            onChange={(value) =>
                                              this.setState({ to_date: value })
                                            }
                                            KeyboardButtonProps={{
                                              "aria-label": "change date",
                                            }}
                                          />
                                        </MuiPickersUtilsProvider>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 col-sm-12 col-sm-auto ">
                                    <div className="d-info-card">
                                      <label>Repeat Every</label>
                                      <div className="cus_trial_toggle">
                                        <TextField
                                          placeholder="Repeat Every"
                                          type="number"
                                          InputProps={{
                                            inputProps: {
                                              max: 2,
                                              min: 1,
                                            },
                                          }}
                                          onChange={(event) => {
                                            this.setState({
                                              repeat_every: event.target.value,
                                            });
                                          }}
                                          value={this.state.repeat_every}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <RadioGroup
                                      aria-label="monthly_radio"
                                      name="monthly_radio"
                                      value={this.state.monthly_radio}
                                      onChange={(event) =>
                                        this.setState({
                                          monthly_radio: event.target.value,
                                        })
                                      }
                                    >
                                      <div className="d-flex">
                                        <FormControlLabel
                                          value="on_day"
                                          control={<Radio />}
                                          label="On day"
                                        />

                                        <div className="col-md-6 col-sm-12 col-sm-auto ">
                                          <TextField
                                            placeholder="Repeat Every"
                                            type="number"
                                            disabled={
                                              this.state.monthly_radio !==
                                              "on_day"
                                                ? true
                                                : false
                                            }
                                            InputProps={{
                                              inputProps: {
                                                max: 2,
                                                min: 1,
                                              },
                                            }}
                                            onChange={(event) => {
                                              this.setState({
                                                days_of_month:
                                                  event.target.value,
                                              });
                                            }}
                                            value={this.state.days_of_month}
                                          />
                                        </div>
                                      </div>
                                      <div className="d-flex">
                                        <FormControlLabel
                                          value="on_the"
                                          control={<Radio />}
                                          label="On the"
                                        />

                                        <div className="col-md-1 col-sm-12 col-sm-auto ">
                                          <Select
                                            className="custom-select1"
                                            value={this.state.week_occurence}
                                            disabled={
                                              this.state.monthly_radio !==
                                              "on_the"
                                                ? true
                                                : false
                                            }
                                            onChange={(event) =>
                                              this.setState({
                                                week_occurence:
                                                  event.target.value,
                                              })
                                            }
                                          >
                                            {list.map((option) => (
                                              <MenuItem
                                                key={option.label}
                                                value={option.value}
                                              >
                                                {option.label}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-sm-auto ">
                                          <Select
                                            className="custom-select1"
                                            value={this.state.selected_day}
                                            disabled={
                                              this.state.monthly_radio !==
                                              "on_the"
                                                ? true
                                                : false
                                            }
                                            onChange={(event) =>
                                              this.setState({
                                                selected_day:
                                                  event.target.value,
                                              })
                                            }
                                          >
                                            {this.state.daysList.map(
                                              (option) => (
                                                <MenuItem
                                                  key={option.name}
                                                  value={option.name}
                                                >
                                                  {option.name}
                                                </MenuItem>
                                              )
                                            )}
                                          </Select>
                                        </div>
                                      </div>
                                    </RadioGroup>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.scan_option === "yearly" ? (
                                <div className="row mt-3 pl-2">
                                  <div className="col-md-4 col-sm-12 col-sm-auto">
                                    <div className="d-info-card">
                                      <label>From</label>
                                      <div className="cus_trial_toggle">
                                        <MuiPickersUtilsProvider
                                          utils={MomentUtils}
                                        >
                                          <KeyboardDateTimePicker
                                            //disableToolbar
                                            variant="inline"
                                            className="date"
                                            format="MMM, DD yyyy HH:mm"
                                            showTodayButton={true}
                                            autoOk={true}
                                            margin="normal"
                                            id="date-picker-inline"
                                            value={this.state.from_date}
                                            onChange={(value) =>
                                              this.setState({
                                                from_date: value,
                                              })
                                            }
                                            KeyboardButtonProps={{
                                              "aria-label": "change date",
                                            }}
                                          />
                                        </MuiPickersUtilsProvider>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4 col-sm-12 col-sm-auto">
                                    <div className="d-info-card">
                                      <label>To</label>
                                      <div className="cus_trial_toggle">
                                        <MuiPickersUtilsProvider
                                          utils={MomentUtils}
                                        >
                                          <KeyboardDateTimePicker
                                            //disableToolbar
                                            variant="inline"
                                            className="date"
                                            format="MMM, DD yyyy HH:mm"
                                            showTodayButton={true}
                                            autoOk={true}
                                            margin="normal"
                                            id="date-picker-inline"
                                            value={this.state.to_date}
                                            onChange={(value) =>
                                              this.setState({ to_date: value })
                                            }
                                            KeyboardButtonProps={{
                                              "aria-label": "change date",
                                            }}
                                          />
                                        </MuiPickersUtilsProvider>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-4 col-sm-12 col-sm-auto ">
                                    <div className="d-info-card">
                                      <label>Repeat Every</label>
                                      <div className="cus_trial_toggle">
                                        <TextField
                                          placeholder="Repeat Every"
                                          type="number"
                                          InputProps={{
                                            inputProps: {
                                              max: 2,
                                              min: 1,
                                            },
                                          }}
                                          onChange={(event) => {
                                            this.setState({
                                              repeat_every: event.target.value,
                                            });
                                          }}
                                          value={this.state.repeat_every}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <RadioGroup
                                      aria-label="monthly_radio"
                                      name="monthly_radio"
                                      value={this.state.monthly_radio}
                                      onChange={(event) =>
                                        this.setState({
                                          monthly_radio: event.target.value,
                                        })
                                      }
                                    >
                                      <div className="d-flex">
                                        <FormControlLabel
                                          value="on_day"
                                          control={<Radio />}
                                          label="On day"
                                        />

                                        <div className="col-md-6 col-sm-12 col-sm-auto ">
                                          <TextField
                                            placeholder="Repeat Every"
                                            type="number"
                                            disabled={
                                              this.state.monthly_radio !==
                                              "on_day"
                                                ? true
                                                : false
                                            }
                                            InputProps={{
                                              inputProps: {
                                                max: 2,
                                                min: 1,
                                              },
                                            }}
                                            onChange={(event) => {
                                              this.setState({
                                                days_of_month:
                                                  event.target.value,
                                              });
                                            }}
                                            value={this.state.days_of_month}
                                          />
                                          <Select
                                            className="custom-select1 ml-3"
                                            value={this.state.month}
                                            // disabled={
                                            //   this.state.monthly_radio !==
                                            //   "on_the"
                                            //     ? true
                                            //     : false
                                            // }
                                            onChange={(event) =>
                                              this.setState({
                                                month: event.target.value,
                                              })
                                            }
                                          >
                                            {month_list.map((option) => (
                                              <MenuItem
                                                key={option.label}
                                                value={option.value}
                                              >
                                                {option.label}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </div>
                                        <div className="col-md-4 col-sm-12 col-sm-auto "></div>
                                      </div>
                                      <div className="d-flex">
                                        <FormControlLabel
                                          value="on_the"
                                          control={<Radio />}
                                          label="On the"
                                        />

                                        <div className="col-md-1 col-sm-12 col-sm-auto ">
                                          <Select
                                            className="custom-select1"
                                            value={this.state.week_occurence}
                                            disabled={
                                              this.state.monthly_radio !==
                                              "on_the"
                                                ? true
                                                : false
                                            }
                                            onChange={(event) =>
                                              this.setState({
                                                week_occurence:
                                                  event.target.value,
                                              })
                                            }
                                          >
                                            {list.map((option) => (
                                              <MenuItem
                                                key={option.label}
                                                value={option.value}
                                              >
                                                {option.label}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </div>
                                        <div className="col-md-1 col-sm-12 col-sm-auto ">
                                          <Select
                                            className="custom-select1"
                                            value={this.state.selected_day}
                                            disabled={
                                              this.state.monthly_radio !==
                                              "on_the"
                                                ? true
                                                : false
                                            }
                                            onChange={(event) =>
                                              this.setState({
                                                selected_day:
                                                  event.target.value,
                                              })
                                            }
                                          >
                                            {this.state.daysList.map(
                                              (option) => (
                                                <MenuItem
                                                  key={option.name}
                                                  value={option.name}
                                                >
                                                  {option.name}
                                                </MenuItem>
                                              )
                                            )}
                                          </Select>
                                        </div>
                                        <div className="col-md-3 col-sm-12 col-sm-auto ml-2 ">
                                          <Select
                                            className="custom-select1 ml-3"
                                            value={this.state.month}
                                            // disabled={
                                            //   this.state.monthly_radio !==
                                            //   "on_the"
                                            //     ? true
                                            //     : false
                                            // }
                                            onChange={(event) =>
                                              this.setState({
                                                month: event.target.value,
                                              })
                                            }
                                          >
                                            {month_list.map((option) => (
                                              <MenuItem
                                                key={option.label}
                                                value={option.value}
                                              >
                                                {option.label}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </div>
                                      </div>
                                    </RadioGroup>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                          {this.state.scan_option === "daily" &&
                          this.state.scanType !== "quick_scan" ? (
                            <span className="text-white pl-2 ml-3">
                              Occures every {this.state.repeat_every} days
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="btn-fp-footer btn-Reset mr-b20"
                        style={{ float: "right" }}
                        onClick={this.createScan}
                        // disabled={this.state.loader}
                      >
                        <h6>
                          Save 
                          {this.state.loader ? (
                            <i className="fa fa-spinner fa-spin"></i>
                          ) : null}
                        </h6>
                      </button>{" "}
                    </div>

                    <div className="black-card d-d-card mt-50">
                      <div className="d-d-card-head scan mb-3">
                        <h4>Vulnerability Scans</h4>
                      </div>
                      {this.state?.reports?.length ? (
                        <TableContainer className="">
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow
                                className="table-header"
                                style={{ height: 30 }}
                              >
                                <TableCell>
                                  <h6> Name</h6>
                                </TableCell>
                                <TableCell>
                                  <h6> User</h6>
                                </TableCell>
                                <TableCell>
                                  <h6> Upcoming scan</h6>
                                </TableCell>
                                <TableCell>
                                  <h6> Severity</h6>
                                </TableCell>
                                <TableCell>
                                  <h6> Status</h6>
                                </TableCell>
                                <TableCell>
                                  <h6> Reports</h6>
                                </TableCell>
                                <TableCell>
                                  <h6> Action</h6>
                                </TableCell>
                              </TableRow>
                            </TableHead>

                            {this.state.reports?.map((data, index) => (
                              <TableRow key={index}>
                                <TableCell>
                                  <div className="spinner-place">
                                    <span> {data?.scan_name} </span>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="spinner-place">
                                    <span> {data?.full_name} </span>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="spinner-place">
                                    <span> {data?.upcoming_scan} </span>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="spinner-place">
                                    <span>
                                      {" "}
                                      {data?.severity
                                        ? data?.severity
                                        : "-"}{" "}
                                    </span>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="spinner-place">
                                    <span>
                                      {" "}
                                      {data?.status ? data?.status : "-"}{" "}
                                    </span>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="spinner-place">
                                    {" "}
                                    {data?.reports ? (
                                      <span
                                        className="cursor"
                                        onClick={() =>
                                          this.props.history.push(
                                            `/dashboard/UpdateVulnerabilityScan/${data.unique_id}`
                                          )
                                        }
                                      >
                                        {data?.reports}
                                      </span>
                                    ) : (
                                      <span>0</span>
                                    )}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="spinner-place">
                                    <Tooltip title="Edit">
                                      <IconButton>
                                        <EditOutlined
                                          onClick={() =>
                                            this.props.history.push(
                                              `/dashboard/UpdateVulnerabilityScan/${data.unique_id}`
                                            )
                                          }
                                        />
                                      </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                      <IconButton>
                                        <DeleteIcon
                                          onClick={() =>
                                            this.setState({
                                              unique_id: data.unique_id,
                                            })
                                          }
                                        />
                                      </IconButton>
                                    </Tooltip>
                                    {/* <Tooltip title="Delete">
                                    <IconButton>
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip> */}
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                          </Table>
                        </TableContainer>
                      ) : (
                        <div
                          style={{
                            paddingTop: "20px",
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "20px",
                            color: "#BC2835",
                          }}
                        >
                          No data available
                        </div>
                      )}
                    </div>
                    {/* <div className="black-card settings-card mr-b20">

                  <div className="b-card-link">
                    <a href="/">
                      Change dashboard password
                      <svg
                        width="23"
                        height="24"
                        viewBox="0 0 23 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M13.8189 12L6.90942 4.70711L8.24929 3.29289L16.4986 12L8.24929 20.7071L6.90942 19.2929L13.8189 12Z"
                          fill="white"
                        />
                      </svg>
                    </a>
                  </div>
                </div> */}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                paddingTop: "20px",
                display: "flex",
                justifyContent: "center",
                fontSize: "20px",
                color: "#BC2835",
              }}
            >
              No device connected
            </div>
          )}
          <Modal
            open={this.state.unique_id}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
              role="document"
            >
              <div className="modal-content modal_fp_content">
                <div className="modal-header forget_pw_header">
                  <button
                    type="button"
                    className="close modal_fp_close_btn"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => this.setState({ unique_id: false })}
                  >
                    <span aria-hidden="true">
                      <svg
                        width="10"
                        height="18"
                        viewBox="0 0 10 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </button>
                  <h5
                    className="modal-title modal_fp_title "
                    id="exampleModalLabel"
                  >
                    Vulnerability Scan
                  </h5>
                </div>

                <div className="modal-body fp_modal_body">
                  <div className="col-12 col-sm-12 col-md-12 modal_fp_body mr-t20 mr-b10">
                    <h6 className="delete-dialog">
                      Are you sure you want to delete vulnerability scan ?
                      <br />
                      <br />
                      <i>Note: This action cannot be undone!</i>
                    </h6>
                  </div>
                </div>
                <div className="modal-footer footer_fp_modal">
                  <button
                    type="button"
                    className="btn-fp-footer btn-Reset"
                    onClick={() => {
                      this.deleteVulnerabilityScan(this.state.unique_id);
                      this.setState({ unique_id: false });
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {this.state.openListModal ? (
            <Modal
              open={this.state.openListModal}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
                role="document"
              >
                <div className="modal-content modal_fp_content">
                  <div className="modal-header forget_pw_header">
                    <button
                      type="button"
                      className="close modal_fp_close_btn"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={this.handleClose}
                    >
                      <span aria-hidden="true">
                        <svg
                          width="10"
                          height="18"
                          viewBox="0 0 10 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </button>
                    <h5
                      className="modal-title modal_fp_title "
                      id="exampleModalLabel"
                    >
                      Paired IOT Devices
                    </h5>
                  </div>

                  <div className="modal-body fp_modal_body custom-modal">
                    <div className="col-12  modal_fp_body">
                      {this.state.selectDevicesList.map((device, index) => (
                        <div key={index}>
                          <label className="checkbox-label">
                            <Checkbox
                              key={index}
                              onChange={(event) =>
                                this.handleClickSelection(event, device)
                              }
                              inputProps={{
                                "aria-label": "primary checkbox",
                              }}
                              checked={
                                device.isSelected === "true" ? true : false
                              }
                              value={device.name}
                            />
                            <span className="mr-r20"></span>
                            {device.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="modal-footer footer_fp_modal">
                    <button
                      type="button"
                      className="btn-fp-footer btn-Reset"
                      onClick={this.updateSelections}
                      disabled={this.state.loader}
                    >
                      Update
                      {/* 
                      {this.state.loader ? (
                        <i className="fa fa-spinner fa-spin"></i>
                      ) : null} */}
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          ) : null}
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default VulnerabilityScan;
