// import React from "react";
// import Button from "@material-ui/core/Button";
// import Menu from "@material-ui/core/Menu";
// import MenuItem from "@material-ui/core/MenuItem";
// import AddBoxIcon from "@material-ui/icons/AddBox";

// class ToggleSideNav extends React.Component {
//   state = {
//     anchorEl: null,
//   };

//   handleClick = (event) => {
//     this.setState({ anchorEl: event.currentTarget });
//   };

//   handleClose = () => {
//     this.setState({ anchorEl: null });
//   };

//   render() {
//     const { anchorEl } = this.state;

//     return (
//       <div>
//         <AddBoxIcon aria-owns={anchorEl ? "simple-menu" : undefined} aria-haspopup="true" onClick={this.handleClick} />
//         {/* Open Menu
//         </Button> */}
//         <Menu
//           style={{ marginTop: 30 }}
//           id="simple-menu"
//           anchorEl={anchorEl}
//           open={Boolean(anchorEl)}
//           onClose={this.handleClose}
//         >
//           <MenuItem onClick={this.handleClose}>Profile</MenuItem>
//           <MenuItem onClick={this.handleClose}>My account</MenuItem>
//           <MenuItem onClick={this.handleClose}>My account</MenuItem>
//           <MenuItem onClick={this.handleClose}>My account</MenuItem>
//           <MenuItem onClick={this.handleClose}>My account</MenuItem>
//         </Menu>
//       </div>
//     );
//   }
// }

// export default ToggleSideNav;

import React, { Component } from "react";
import $ from "jquery";
import battery6 from "../../../assets/images/battery6.png";
import battery5 from "../../../assets/images/battery5.png";
import battery4 from "../../../assets/images/battery4.png";
import battery3 from "../../../assets/images/battery3.png";
import battery2 from "../../../assets/images/battery2.png";
import battery1 from "../../../assets/images/battery1.png";
import battery0 from "../../../assets/images/battery0.png";
import charging from "../../../assets/images/charging.svg";
import unknown from "../../../assets/images/unknown.png";
import alert from "../../../assets/images/alert.png";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import fileSharingMethods from "../../../services/fileSharingApi";
import mqtt from "mqtt";
import { Notification } from "../../../common/Notification";

class ToggleSideNav extends Component {
  toggleSideNav() {
    $(".sidebar-wrap").toggleClass("show-menu");
    $(".menu-toggle-btn").toggleClass("t-change-btn");
  }

  state = {
    anchorEl: null,
    editModal: false,
    folderName: "",
    modalTitle: "Create a folder",
    modalLabel: "Folder Name",
    batteryData: "",
    refreshOnce: false,
    count: 0,
    // selectedDevice: this.props.location.state.selectedDevice,
  };

  componentDidMount() {
    // if (this.props.batteryRefresh === true) {
    this.run();
    this.getNotificationCount();
    // this.run1();
    // console.log("check");
    // console.log(sessionStorage.getItem("BatteryData"));
    // if (sessionStorage.getItem("BatteryData")) {
    //   this.setState({
    //     batteryData: sessionStorage.getItem("BatteryData"),
    //   });
    // }
    // }
  }

  run = () => {
    var mosquitto = mqtt.connect("wss://cylenium.io", {
      port: 8083,
      username: "mqttdonald",
      password: "mqttdonald",
      clean: true,
      retain: false,
    });
    var device_id = JSON.parse(
      window.sessionStorage.getItem("ConnectedDevice")
    )?.device_id;
    // console.log(device_id);
    mosquitto.on("connect", function () {
      var successTopic = device_id + "/device/app";
      mosquitto.subscribe(successTopic, { retain: false, clean: true, qos: 2 });
      // console.log(successTopic);
    });
    mosquitto.on("message", (topic, sub_message) => {
      var payload = sub_message.toString();
      // console.log(JSON.parse(payload).value);
      // this.setState({
      //   batteryData: JSON.parse(payload).value,
      // });
      // if (payload != null) {
      sessionStorage.setItem("BatteryData", JSON.parse(payload).value);
      this.setState({ batteryData: sessionStorage.getItem("BatteryData") });
      // }
    });
    // console.log(sessionStorage.getItem("BatteryData"));
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
    this.setState({ editModal: false });
  };

  handleOpen = (type) => {
    this.setState({
      editModal: true,
      updateType: type,
      errorModal: "",
    });

    if (type === "File") {
      // this.setState({
      //   modalTitle: "SSID",
      //   modalLabel: "Enter new SSID",
      // });
    }

    if (type === "Folder") {
      //  Create Folder
      this.setState({
        modalTitle: "Create a folder",
        modalLabel: "Folder Name",
      });
    }
  };

  handleUpdateValue = async (event) => {
    await this.setState({
      [event.target.name]: event.target.value,
    });
  };

  updateTextSettingData = async () => {
    if (this.state.updateType === "folderName") {
      if (this.state.folderName === "") {
        this.setState({
          errorModal: "Please enter a folder name",
        });
      } else {
        this.setState({
          errorModal: "",
          loader: true,
        });

        var updateObject = {
          path: `/dRisk/${this.state.selectedDevice.device_id}/${this.state.folderName}`,
        };

        const response = await fileSharingMethods.createDirectory(updateObject);

        if (response.data.statusCode === 200) {
          this.resetValues();
        }
      }
    }
  };
  getNotificationCount = async () => {
    const response = await fileSharingMethods.getNotificationCount();
    console.log(response.data.result);
    this.setState(response.data.result);
  };

  resetValues = () => {
    this.setState({
      anchorEl: null,
      editModal: false,
      folderName: "",
      modalTitle: "Create a folder",
      modalLabel: "Folder Name",
    });
  };

  render() {
    const { anchorEl } = this.state;
    return (
      <div className="d-flex">
        <Notification value={this.state.count} />
        <div>
          <div>
            {/* <AddBoxIcon
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}
          /> */}
            {/* Open Menu
        </Button> */}
            <Menu
              style={{ marginTop: 30 }}
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
            >
              <MenuItem onClick={() => this.handleOpen("File")}>
                Add File
              </MenuItem>
              <MenuItem onClick={() => this.handleOpen("Folder")}>
                Create New Folder
              </MenuItem>
            </Menu>
            {/* <img src={battery} alt="battery" height="25px" width="25px" align="left" style={{ paddingRight: "10px" }} /> */}
            {this.state?.chargingState ? (
              <img src={charging} width="28" height="30" alt="" />
            ) : (
              <>
                {(this.state?.batteryLevel == "" ||
                  this.state?.batteryLevel == "0" ||
                  this.state?.batteryLevel === null) && (
                  <img src={unknown} width="28" height="30" alt="" />
                )}
                {/* {this.state?.batteryLevel < 5 && <img src={alert} width="35" height="30" />} */}
                {this.state?.batteryLevel >= 1 &&
                  this.state?.batteryLevel < 15 && (
                    <img src={battery0} width="28" height="30" alt="" />
                  )}
                {this.state?.batteryLevel >= 15 &&
                  this.state?.batteryLevel < 30 && (
                    <img src={battery1} width="28" height="30" alt="" />
                  )}
                {this.state?.batteryLevel >= 30 &&
                  this.state?.batteryLevel < 45 && (
                    <img src={battery2} width="28" height="30" alt="" />
                  )}
                {this.state?.batteryLevel >= 45 &&
                  this.state?.batteryLevel < 60 && (
                    <img src={battery3} width="28" height="30" alt="" />
                  )}
                {this.state?.batteryLevel >= 60 &&
                  this.state?.batteryLevel < 75 && (
                    <img src={battery4} width="28" height="30" alt="" />
                  )}
                {this.state?.batteryLevel >= 75 &&
                  this.state?.batteryLevel < 90 && (
                    <img src={battery5} width="28" height="30" alt="" />
                  )}
                {this.state?.batteryLevel >= 90 && (
                  <img src={battery6} width="28" height="30" alt="" />
                )}
              </>
            )}

            {/* <img src={battery6} width="35" height="30" /> */}
            <button
              type="button"
              className="menu-toggle-btn"
              onClick={this.toggleSideNav}
            >
              <svg
                className="toggle-icon"
                width="18"
                height="12"
                viewBox="0 0 18 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 12H9V10H0V12Z" fill="#E5E5E5" />
                <path d="M0 7H18V5H0V7Z" fill="#E5E5E5" />
                <path d="M9 0V2H18V0H9Z" fill="#E5E5E5" />
              </svg>
              <svg
                className="close-menu"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                  fill="#ffffff"
                />
              </svg>
            </button>
          </div>

          {this.state.editModal ? (
            <Modal
              open={this.state.editModal}
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered modal_dialog_forgot_pw"
                role="document"
              >
                <div className="modal-content modal_fp_content">
                  <div className="modal-header forget_pw_header">
                    <button
                      type="button"
                      className="close modal_fp_close_btn"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={this.handleClose}
                    >
                      <span aria-hidden="true">
                        <svg
                          width="10"
                          height="18"
                          viewBox="0 0 10 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.91937 9.00008L9.82886 1.70718L8.48899 0.292969L0.23964 9.00008L8.48899 17.7072L9.82886 16.293L2.91937 9.00008Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </button>
                    <h5
                      className="modal-title modal_fp_title "
                      id="exampleModalLabel"
                    >
                      {this.state.modalTitle}
                    </h5>
                  </div>

                  <div className="modal-body fp_modal_body">
                    <div className="col-12 col-sm-12 col-md-12 modal_fp_body">
                      <div className="fp_label">
                        <h6>
                          {this.state.modalLabel}
                          <em className="mandatory">* </em>
                        </h6>

                        {this.state.updateType === "Folder" ? (
                          <input
                            type="text"
                            className="fp_modal_input"
                            value={this.state.folderName}
                            name="folderName"
                            onChange={this.handleUpdateValue}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer footer_fp_modal">
                    <button
                      type="button"
                      className="btn-fp-footer btn-Reset"
                      onClick={this.updateTextSettingData}
                      disabled={this.state.loader}
                    >
                      Create
                      {this.state.loader ? (
                        <i className="fa fa-spinner fa-spin"></i>
                      ) : null}
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
          ) : null}
        </div>
      </div>
    );
  }
}

export default ToggleSideNav;
