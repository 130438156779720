import axios from "axios";
const url = sessionStorage?.getItem("localChatServer");
const fileSharingUrl = sessionStorage?.getItem("localServerFileSharing");
const localApi = axios.create({
  baseURL: url,
  headers: {
    "Content-Type": "application/json",
  },
});

localApi.interceptors.request.use(function (config) {
  const token = sessionStorage.getItem("UserDetails")
    ? JSON.parse(sessionStorage.getItem("UserDetails")).token
    : "";
  config.headers["Auth"] = token;
  return config;
});
 console.log(fileSharingUrl)
// File sharing
const fileSharingApi = axios.create({
  baseURL: fileSharingUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

fileSharingApi.interceptors.request.use(function (config) {
  const token = sessionStorage.getItem("UserDetails")
    ? JSON.parse(sessionStorage.getItem("UserDetails")).token
    : "";
  config.headers["Auth"] = token;
  return config;
});

export { localApi, fileSharingApi };
