import React, { useEffect, useRef, useState } from 'react';
import Slider from 'rc-slider';
import settingsMethods from "../../services/settingsApi";
import 'rc-slider/assets/index.css';
import { toast } from 'react-toastify';

const WiFiSlider = ({ dataValue, disabled }) => { // Added `disabled` prop
  const [value, setValue] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [loading, setLoading] = useState(false);
  const timeoutRef = useRef(null);

  // Marks for the slider
  const marks = {
    0: { label: '0dB', style: { color: 'white' } },
    1: { label: '0.25dB', style: { color: 'white' } },
    2: { label: '0.5dB', style: { color: 'white' } },
    3: { label: '1dB', style: { color: 'white' } },
    4: { label: '2dB', style: { color: 'white' } },
    5: { label: '4dB', style: { color: 'white' } },
    6: { label: '8dB', style: { color: 'white' } },
    7: { label: '16dB', style: { color: 'white' } },
    8: { label: '31.75dB', style: { color: 'white' } }
  };

  const updateBackend = async (newValue) => {
    setLoading(true);
    try {
      const updatedData = {
        type: "wifi_attenuation",
        device_id: newValue,
        wifi_attenuation: newValue,
      };
      await settingsMethods.updateSettings(updatedData);
      console.log(`API updated with value: ${newValue}`);
    } catch (error) {
      toast.error("Error while updating!");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDragStart = () => {
    setIsDragging(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    updateBackend(value);
  };

  const handleChange = (newValue) => {
    setValue(newValue);

    if (!isDragging) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        updateBackend(newValue);
      }, 100);
    }
  };

  useEffect(() => {
    setValue(dataValue);
  }, [dataValue]);

  // Custom styles for rc-slider
  const sliderStyle = {
    track: {
      backgroundColor: disabled ? '#cccccc' : '#6bc4e2', // Greyed out when disabled
      height: 3
    },
    rail: {
      backgroundColor: '#d8d8d8',
      height: 3
    },
    handle: {
      borderColor: disabled ? '#cccccc' : '#6bc4e2', // Greyed out when disabled
      height: 25,
      width: 25,
      marginTop: -11,
      backgroundColor: disabled ? '#f0f0f0' : '#fff', // Greyed out handle
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
    },
    mark: {
      backgroundColor: '#fff',
      height: 8,
      width: 1,
      marginTop: -3,
    }
  };

  const CustomHandle = (props) => {
    return (
      <div {...props}>
        <div className="middle-bar" />
      </div>
    );
  };

  return (
    <div className="col-md-8 col-sm-12 custom-slider1">
      <label style={{ color: "white", fontSize: 12 }}>
        WiFi Attenuation - ({marks[value]?.label || "0dB"})
        <span className="text-danger font-italic">
          {disabled ? " - Turn off ghost mode to modify " : ""}
        </span>
      </label>
      <div style={{ padding: '13px 0' }}>
        <Slider
          ThumbComponent={CustomHandle}
          min={0}
          max={8}
          value={value}
          marks={marks}
          step={null}
          onChange={!disabled ? handleChange : undefined} // Disable onChange if disabled
          onBeforeChange={!disabled ? handleDragStart : undefined} // Disable onBeforeChange if disabled
          onAfterChange={!disabled ? handleDragEnd : undefined} // Disable onAfterChange if disabled
          railStyle={sliderStyle.rail}
          trackStyle={sliderStyle.track}
          handleStyle={sliderStyle.handle}
          dotStyle={sliderStyle.mark}
          activeDotStyle={sliderStyle.mark}
        />
      </div>
      {loading && <p style={{ color: "white", fontSize: 12, marginTop: 20, marginLeft: -10 }}>Updating...</p>}
    </div>
  );
};

export default WiFiSlider;
