import axios from "axios";
import api from "./api";
import { toast } from "react-toastify";
const token = sessionStorage.getItem("UserDetails")
  ? JSON.parse(sessionStorage.getItem("UserDetails")).token
  : "";
const config = {
  headers: {
    Auth: ` ${token}`,
  },
};
function getHardwareDevicesList(getDevices) {
  return new Promise((resolve, reject) => {
    api.post("/fileSharing/dataSharing", getDevices).then((response) => {
      resolve(response);
    });
  });
}

// function getFileStructure(pathName) {
//   return new Promise((resolve, reject) => {
//     api.post("/fileSharing/fileStructure", pathName).then((response) => {
//       resolve(response);
//     });
//   });
// }
function getFileStructure(pathName) {
  if (!sessionStorage?.getItem("localServerFileSharing")) {
    window.location.reload();
  }
  return new Promise((resolve, reject) => {
    axios
      .post(
        sessionStorage?.getItem("localServerFileSharing") + "/fileStructure",
        pathName,
        config
      )
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        toast.error(
          "Unable to reach the device. Please confirm your connection and try again"
        );
        reject(
          "Unable to reach the device. Please confirm your connection and try again"
        );
      });
  });
}

// function createDirectory(create) {
//   return new Promise((resolve, reject) => {
//     api.post("/fileSharing/createDirectory", create).then((response) => {
//       resolve(response);
//     });
//   });
// }

function createDirectory(create) {
  const fileSharingUrl = sessionStorage?.getItem("localServerFileSharing");

  return new Promise((resolve, reject) => {
    axios.post(fileSharingUrl + "/createDirectory", create,config).then((response) => {
      resolve(response);
    });
  });
}

// function renameFiles(create) {
//   return new Promise((resolve, reject) => {
//     api.post("/fileSharing/renameFiles", create).then((response) => {
//       resolve(response);
//     });
//   });
// }
function renameFiles(create) {
  return new Promise((resolve, reject) => {
    const fileSharingUrl = sessionStorage?.getItem("localServerFileSharing");

    axios
      .post(fileSharingUrl + "/renameFiles", create, config)
      .then((response) => {
        resolve(response);
      });
  });
}

function downloadFolders(create) {
  return new Promise((resolve, reject) => {
    const fileSharingUrl = sessionStorage?.getItem("localServerFileSharing");
    axios
      .get(
        fileSharingUrl + `/downloadFoldersWeb/{"path":"${create.path}"}`,
        config
      )
      .then((response) => {
        resolve(response);
      });
  });
}
// function downloadFolders(create) {
//   return new Promise((resolve, reject) => {
//     api
//       .get(`/fileSharing/downloadFoldersWeb/{"path":"${create.path}"}`)
//       .then((response) => {
//         resolve(response);
//       });
//   });
// }

// function downloadFiles(create) {
//   return new Promise((resolve, reject) => {
//     api
//       .get(`/fileSharing/downloadFiles/{"path":"${create.path}"}`)
//       .then((response) => {
//         resolve(response);
//       });
//   });
// }

function downloadFiles(create) {
  return new Promise((resolve, reject) => {
    const fileSharingUrl = sessionStorage?.getItem("localServerFileSharing");

    axios
      .get(fileSharingUrl + `/downloadFiles/{"path":"${create.path}"}`, config)
      .then((response) => {
        resolve(response);
      });
  });
}

function moveFiles(create) {
  return new Promise((resolve, reject) => {
    const fileSharingUrl = sessionStorage?.getItem("localServerFileSharing");

    axios
      .post(fileSharingUrl + "/moveFiles", create, config)
      .then((response) => {
        resolve(response);
      });
  });
}
// function moveFiles(create) {
//   return new Promise((resolve, reject) => {
//     api.post("/fileSharing/moveFiles", create).then((response) => {
//       resolve(response);
//     });
//   });
// }

function copyFiles(create) {
  return new Promise((resolve, reject) => {
    const fileSharingUrl = sessionStorage?.getItem("localServerFileSharing");

    axios
      .post(fileSharingUrl + "/copyFiles", create, config)
      .then((response) => {
        resolve(response);
      });
  });
}
// function copyFiles(create) {
//   return new Promise((resolve, reject) => {
//     api.post("/fileSharing/copyFiles", create).then((response) => {
//       resolve(response);
//     });
//   });
// }
function copyFilesToCloud(create) {
  return new Promise((resolve, reject) => {
    api.post("/fileSharing/copyToCloud", create).then((response) => {
      resolve(response);
    });
  });
}

function deleteFile(create) {
  return new Promise((resolve, reject) => {
    const fileSharingUrl = sessionStorage?.getItem("localServerFileSharing");

    axios
      .post(fileSharingUrl + "/deleteFile", create, config)
      .then((response) => {
        resolve(response);
      });
  });
}
// function deleteFile(create) {
//   return new Promise((resolve, reject) => {
//     api.post("/fileSharing/deleteFile", create).then((response) => {
//       resolve(response);
//     });
//   });
// }

function validateSize(create) {
  return new Promise((resolve, reject) => {
    const fileSharingUrl = sessionStorage?.getItem("localServerFileSharing");
    console.log(create);
    api
      .post(fileSharingUrl + "/validatingDeviceSize", create)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        toast.error("Upload failed!");
      });
  });
}
// function validateSize(create) {
//   return new Promise((resolve, reject) => {
//     api.post("/fileSharing/validatingDeviceSize", create).then((response) => {
//       resolve(response);
//     });
//   });
// }

function uploadFiles(create) {
  return new Promise((resolve, reject) => {
    const fileSharingUrl = sessionStorage?.getItem("localServerFileSharing");

    api
      .post(fileSharingUrl + "/uploadFiles", create)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        toast.error("Upload failed!");
      });
  });
}
// function uploadFiles(create) {
//   return new Promise((resolve, reject) => {
//     api.post("/fileSharing/uploadFiles", create).then((response) => {
//       resolve(response);
//     });
//   });
// }

function storagePlans(create) {
  return new Promise((resolve, reject) => {
    api.post("/stripe/storagePlans", create).then((response) => {
      resolve(response);
    });
  });
}

function stripePayment(create) {
  return new Promise((resolve, reject) => {
    api.post("/stripe/payment-sheet", create).then((response) => {
      resolve(response);
    });
  });
}
function fileStructure(create) {
  return new Promise((resolve, reject) => {
    const fileSharingUrl = sessionStorage?.getItem("localServerFileSharing");

    axios
      .post(fileSharingUrl + "/fileStructure", create, config)
      .then((response) => {
        resolve(response);
      });
  });
}
// function fileStructure(create) {
//   return new Promise((resolve, reject) => {
//     api.post("/fileSharing/fileStructure", create).then((response) => {
//       resolve(response);
//     });
//   });
// }
function folderStructure(create) {
  return new Promise((resolve, reject) => {
    const fileSharingUrl = sessionStorage?.getItem("localServerFileSharing");

    axios
      .post(fileSharingUrl + "/getFolders", create, config)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        toast.error(
          "Unable to reach the device. Please confirm your connection and try again"
        );
        reject(
          "Unable to reach the device. Please confirm your connection and try again"
        );
      });
  });
}
// function folderStructure(create) {
//   return new Promise((resolve, reject) => {
//     api.post("/fileSharing/getFolders", create).then((response) => {
//       resolve(response);
//     });
//   });
// }
function getNotificationCount(create) {
  return new Promise((resolve, reject) => {
    api.get("/user/getNotificationCount").then((response) => {
      resolve(response);
    });
  });
}

const fileSharingMethods = {
  getHardwareDevicesList,
  getFileStructure,
  createDirectory,
  renameFiles,
  downloadFiles,
  downloadFolders,
  moveFiles,
  copyFiles,
  deleteFile,
  validateSize,
  uploadFiles,
  storagePlans,
  stripePayment,
  getNotificationCount,
  fileStructure,
  folderStructure,
  copyFilesToCloud,
};

export default fileSharingMethods;
