import React, { Component } from "react";
import fileSharingMethods from "../../../services/fileSharingApi";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowBack from "@material-ui/icons/ArrowBack";
import FolderIcon from "@material-ui/icons/Folder";
import moment from "moment";
import bytes from "bytes";
import { toast } from "react-toastify";
class CopyToDrive extends Component {
  state = {
    mountpoint: this.props.mountpoint,
    path: "",
    device_id: this.props.device_id,
    selectedDevice: this.props.selectedDevice.device_id,
    fileStructureList: [],
    token: JSON.parse(window.sessionStorage.getItem("UserDetails")).token,
    count: 0,
    currentWorkingPath: "",
    stack: [],
  };

  componentDidMount = async () => {
    // this.subscribe("", "initial");
    this.fetchFileStructure();
  };

  fetchFileStructure = async () => {
    this.setState({
      loader: true,
    });
    var payload = {
      module: "file_structure_request",
      device_id: this.state.selectedDevice,
      // selectedDevice: this.state.selectedDevice,
      path: this.state.mountpoint,
    };
    const response = await fileSharingMethods.folderStructure(payload);
    if (response.data.statusCode === 200) {
      this.setState({
        fileStructureList: response.data.result,
        loader: false,
      });
    }
  };

  subscribe = async (data, typeOfSubscribe) => {
    switch (typeOfSubscribe) {
      case "initial":
        this.setState({ currentWorkingPath: "" });
        break;

      case "pre":
        let temp1 = this.state.stack;
        temp1.pop();
        console.log(temp1);
        this.setState({ stack: temp1 });
        break;

      case "post":
        console.log(data);
        const temp = this.state.stack;
        temp.push(data);

        this.setState({ stack: temp });
        break;

      default:
        break;
    }

    this.setState({
      loader: true,
    });
    var payload = {
      module: "file_structure_request",
      device_id: this.state.device_id,
      selectedDevice: this.state.selectedDevice,
      path: this.state.stack.length
        ? this.state.mountpoint + "/" + this.state.stack.join("/")
        : this.state.mountpoint,
    };
    const response = await fileSharingMethods.folderStructure(payload);
    if (response.data.statusCode === 200) {
      this.setState({
        fileStructureList: response.data.result,
        loader: false,
      });
    }
    // var mosquitto = mqtt.connect("wss://cylenium.io", {
    //   port: 8083,
    //   username: "mqttdonald",
    //   password: "mqttdonald",
    //   clean: true,
    //   retain: false,
    // });
    // mosquitto.on("connect", () => {
    //   var successTopic = device_id + "/file_structure/app";
    //   var comms = "drisk/hardware";
    //   mosquitto.subscribe(successTopic, { retain: false, clean: true, qos: 2 });
    //   var payload = {
    //     module: "file_structure_request",
    //     mountpoint: mountpoint,
    //     path: this.state.currentWorkingPath,
    //     device_id: device_id,
    //     selected_device_id: selectedDevice,
    //   };
    //   mosquitto.publish(comms, JSON.stringify(payload));
    //   mosquitto.on("message", (topic, sub_message) => {
    //     var payload = sub_message.toString();
    //     this.setState({ fileStructureList: JSON.parse(payload).result });
    //   });
    // });
  };
  handleDevice = async (data) => {
    this.setState({ mountpoint: data, stack: [], loader: true });
    var payload = {
      module: "file_structure_request",
      device_id: this.state.selectedDevice,
      selectedDevice: this.state.selectedDevice,
      path: data,
    };
    const response = await fileSharingMethods.folderStructure(payload);
    if (response.data.statusCode === 200) {
      this.setState({
        fileStructureList: response.data.result,
        loader: false,
      });
    }
  };
  copyFile = async () => {
    var filename = this.props.filename;
    var device_id = this.state.selectedDevice;

    var payload = {
      module: "copy_drive",
      filename: filename,
      desPath: this.state.stack.length
        ? this.state.mountpoint + "/" + this.state.stack.join("/")
        : this.state.mountpoint,
      device_id: device_id,
    };
    const response = await fileSharingMethods.copyFilesToCloud(payload);
    if (response.data.statusCode === 200) {
      toast.success(response?.data?.message);
    } else toast.error(response?.data?.message);

    this.subscribe();
  };

  render() {
    console.log("file structure", this.props.selected_device);
    return (
      <div className="">
        <h6>
          <div>
            {/* {this.getCharCount(currentPath1) > 2 ? ( */}
            <ArrowBack
              onClick={() => {
                this.subscribe(this.state.currentWorkingPath, "pre");
              }}
            >
              Back
            </ArrowBack>
            {/* ) : null} */}
            Select Destination
            <em className="mandatory">* </em>
          </div>
          <div className="col-md-12 ">
            {this?.props?.selectedDevice?.external_drive?.length ? (
              <select
                className="fp_modal_input"
                onChange={(event) => {
                  this.handleDevice(event.target.value);
                }}
                value={this.state.mountpoint}
                disabled={this.state.loader}
              >
                {this?.props?.selectedDevice?.external_drive?.map(
                  (device, index) => (
                    <option value={device.device_name} key={index}>
                      {device.device_name}
                    </option>
                  )
                )}
              </select>
            ) : null}
          </div>
          {this.state.loader ? (
            <CircularProgress
              color="secondary"
              style={{ margin: "0% 0% 0% 50%" }}
            />
          ) : null}
        </h6>
        <ul className="filesharing-card row custom-modal">
          {this.state.fileStructureList.length > 0 ? (
            this.state.fileStructureList.map((data, index) => {
              if (data.type === "directory") {
                return (
                  <li
                    key={data.fileid}
                    className="col-md-12"
                    onClick={() => {
                      this.subscribe(data.name, "post");
                    }}
                  >
                    <div className="col-2 col-sm-2 col-md-2">
                      <span className="drop-icons-left">
                        <FolderIcon />
                      </span>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 header_button_flex">
                      <div className="head_btn_flex">
                        <div className="head_btn_flex_headings">
                          <h5 className="drisk-name">{data.name}</h5>
                          <h6
                            className="drisk-date"
                            style={{
                              fontSize: "13px !important",
                              fontWeight: "500 !important",
                              color: "#6bc4e2 !important",
                              fontFamily: "Poppins-Regular !important",
                            }}
                          >
                            {moment(data.lastmod).format("LL")}
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div className="col-2 col-sm-2 col-md-2 header_button_flex mr-t10">
                      <div className="head_btn_flex">
                        <div className="head_btn_flex_headings">
                          <h6 className="drisk-date">
                            {bytes.format(data.size, {
                              unitSeparator: " ",
                            })}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              }
            })
          ) : !this.state.loader ? (
            <li style={{ width: "100%", textAlign: "center" }}>
              <div
                className="text-center registered-title mr-b20 mr-t20 "
                style={{ width: "100%", textAlign: "center" }}
              >
                <h6
                  style={{
                    color: "#C12A37",
                    fontSize: "15px",
                    textAlign: "center",
                  }}
                >
                  No Data Available
                </h6>
              </div>
            </li>
          ) : null}
        </ul>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            type="button"
            // className="btn-fp-footer btn-Reset"
            style={{
              backgroundColor: "#c12a37",
              color: "white",
              borderRadius: "10px",
              height: "38px",
              width: "150px",
            }}
            onClick={this.copyFile}
            disabled={this.state.loader}
          >
            Copy 
          </button>
        </div>
      </div>
    );
  }
}
export default CopyToDrive;
