import api from "./api";
import { localApi } from "./localApi";

function getGroupDetails(deviceDetails) {
  return new Promise((resolve, reject) => {
    api.post("/cot/getGroupDetails", deviceDetails).then((response) => {
      resolve(response);
    });
  });
}
function addGroup(deviceDetails) {
  return new Promise((resolve, reject) => {
    api.post("/cot/createGroup", deviceDetails).then((response) => {
      resolve(response);
    });
  });
}
function heartBeat(deviceDetails) {
  return new Promise((resolve, reject) => {
    api.post("/cot/heartbeat", deviceDetails).then((response) => {
      resolve(response);
    });
  });
}
function joinRoom(deviceDetails, groupId) {
  return new Promise((resolve, reject) => {
    api.post(`/cot/room/${groupId}/join`, deviceDetails).then((response) => {
      resolve(response);
    });
  });
}
function updateCOTStatus(updateObject) {
  return new Promise((resolve, reject) => {
    api.post("/cot/updateCOTStatus", updateObject).then((response) => {
      resolve(response);
    });
  });
}
function getUsers(updateObject) {
  return new Promise((resolve, reject) => {
    api.post("/user/searchRegisteredUsers", updateObject).then((response) => {
      resolve(response);
    });
  });
}
function getGroupMember(updateObject) {
  return new Promise((resolve, reject) => {
    api.post("/cot/getMemberDetails", updateObject).then((response) => {
      resolve(response);
    });
  });
}
function removeMemberFromCot(updateObject) {
  return new Promise((resolve, reject) => {
    api.post("/cot/removeMemberFromCOT", updateObject).then((response) => {
      resolve(response);
    });
  });
}
function deleteCotApi(updateObject) {
  return new Promise((resolve, reject) => {
    api.post("/cot/deleteGroup", updateObject).then((response) => {
      resolve(response);
    });
  });
}
function addMemberToCot(updateObject) {
  return new Promise((resolve, reject) => {
    api.post("/cot/addGroupMember", updateObject).then((response) => {
      resolve(response);
    });
  });
}
// Server call for uploading
// function uploadChatMedia(data) {
//   return new Promise((resolve, reject) => {
//     api.post("/cot/uploadMediaFile", data).then((response) => {
//       resolve(response);
//     });
//   });
// }
// P2P call for uploading
function uploadChatMedia(data) {
  return new Promise((resolve, reject) => {
    localApi.post("/uploadMediaFile", data).then((response) => {
      resolve(response);
    });
  });
}
const cotMethods = {
  getGroupDetails,
  updateCOTStatus,
  getUsers,
  addGroup,
  heartBeat,
  joinRoom,
  getGroupMember,
  removeMemberFromCot,
  addMemberToCot,
  deleteCotApi,
  uploadChatMedia,
};

export default cotMethods;
